import React, { useState } from 'react';

const AdminUserRoleManagement = () => {
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [selectedRole, setSelectedRole] = useState(null);
    const [newRoleName, setNewRoleName] = useState('');
    const [editingRights, setEditingRights] = useState(null);


    const adminRights = {
        canCreateAndManageChatbots: true,
        canManageOwnFiles: true,
        canSeeChatbotDashboard: true,
        defaultDriveFileSize: 1000, // Example size in MB
        defaultChatbotFileSize: 500, // Example size in MB
        defaultChatbotCount: 100 // Example count
    };

    const defaultRights = {
        canCreateAndManageChatbots: false,
        canManageOwnFiles: true,
        canSeeChatbotDashboard: false,
        defaultDriveFileSize: 100,
        defaultChatbotFileSize: 50,
        defaultChatbotCount: 2
    };

    const [roles, setRoles] = useState([
        { id: 'admin', name: 'Admin', rights: adminRights, isDefault: true },
        { id: 'user', name: 'User', rights: defaultRights, isDefault: true }
    ]);

    const handleCreateRole = () => {
        if (newRoleName.trim() !== '') {
            setRoles([...roles, { id: Date.now().toString(), name: newRoleName, rights: { ...defaultRights }, isDefault: false }]);
            setNewRoleName('');
        }
    };

    const handleDeleteRole = (roleId) => {
        const role = roles.find(r => r.id === roleId);
        if (role && !role.isDefault) {
            setSelectedRole(roleId);
            setShowConfirmationModal(true);
        }
    };

    const confirmDeleteRole = () => {
        setRoles(roles.filter(role => role.id !== selectedRole));
        setShowConfirmationModal(false);
    };

    const handleEditRights = (roleId) => {
        const role = roles.find(r => r.id === roleId);
        if (role && role.name !== 'Admin') {
            setEditingRights({ ...role.rights });
            setSelectedRole(roleId);
        }
    };

    const saveRights = () => {
        setRoles(roles.map(role => role.id === selectedRole ? { ...role, rights: editingRights } : role));
        setEditingRights(null);
        setSelectedRole(null);
    };

    const changeRight = (right, value) => {
        setEditingRights({ ...editingRights, [right]: value });
    };

    const renderRoleRights = (role) => {
        return (
            <div>
                {Object.entries(role.rights).map(([key, value]) => (
                    <div key={key}>
                        <label>{key}</label>
                        <input
                            type="checkbox"
                            checked={value}
                            onChange={() => changeRight(key, !value)}
                            disabled={role.name === 'Admin'}
                        />
                    </div>
                ))}
            </div>
        );
    };

    const renderRoles = () => {
        return roles.map((role) => (
            <div key={role.id}>
                <h3>{role.name}</h3>
                {role.id === selectedRole && editingRights ? renderRoleRights(role) : null}
                <button onClick={() => handleEditRights(role.id)}>Edit Rights</button>
                <button onClick={() => handleDeleteRole(role.id)} disabled={role.isDefault}>Delete</button>
            </div>
        ));
    };

    return (
        <div>
            <h1>User Role Management</h1>
            <input
                type="text"
                value={newRoleName}
                onChange={(e) => setNewRoleName(e.target.value)}
                placeholder="Enter role name"
            />
            <button onClick={handleCreateRole}>Add Role</button>
            {renderRoles()}
            {showConfirmationModal &&
                <div>
                    <p>Are you sure you want to delete this role?</p>
                    <button onClick={confirmDeleteRole}>Yes</button>
                    <button onClick={() => setShowConfirmationModal(false)}>No</button>
                </div>
            }
            {editingRights &&
                <div>
                    <button onClick={saveRights}>Save Rights</button>
                </div>
            }
        </div>
    );
};

export default AdminUserRoleManagement;
