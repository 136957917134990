import React from 'react';
import "./About.css";
import { Link } from 'react-router-dom';

const Card = ({ image, title, description, link }) => {
  return (
    <div className="card">
      <img src={`${process.env.PUBLIC_URL}/${image}`} alt={title} />
      <h2>{title}</h2>
      <p>{description}</p>
      <Link target={'_blank'} to={link}>Mehr Erfahren</Link>
    </div>
  );
};

const About = () => {
  return (
    <div className='about'>

      <div className='aboutContainer'>
        <h1 className='aboutTitle'>Über uns</h1>
        <p className='text'>Am PET sind viele Steakholder beteiligt. Hier sind die 4 wichtigsten aufgeführt.</p>
        <div className="cardContainer">
          <Card image="stiftung.jpg" title="Stiftung - StIL" description="Projektträger" link="https://stiftung-hochschullehre.de/foerderung/" />
          <Card image="unihoh.jpg" title="Uni Hohenheim" description="Projektnehmer" link="https://uni-hohenheim.de/" />
          <Card image="dellfi.jpg" title="DeLLFi Projekt" description="Digitalisierungsprojekt" link="https://www.uni-hohenheim.de/projekt-dellfi" />
          <Card image="matthias.jpg" title="Matthias Wölfel" description="PET - Erfinder" link="https://www.h-ka.de/die-hochschule-karlsruhe/organisation-personen/personen-a-z/person/matthias-woelfel" />
        </div>
      </div>

    </div>

  );
}

export default About;
