export const validatePassword = (password) => {
    const regex = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/;
    return regex.test(password);
};

export const formatBytes = (bytes, decimals = 2) => {
    if (!bytes || bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export const convertToBytes = (size, unit) => {
    const unitMapping = {
        "Bytes": 1,
        "KB": 1024,
        "MB": 1024 * 1024,
        "GB": 1024 * 1024 * 1024,
        "TB": 1024 * 1024 * 1024 * 1024,
        "PB": 1024 * 1024 * 1024 * 1024 * 1024,
        "EB": 1024 * 1024 * 1024 * 1024 * 1024 * 1024,
        "ZB": 1024 * 1024 * 1024 * 1024 * 1024 * 1024 * 1024,
        "YB": 1024 * 1024 * 1024 * 1024 * 1024 * 1024 * 1024 * 1024,
    };
    return size * (unitMapping[unit] || 1);
};

export const formatBytesForUnit = (bytes) => {
    if (bytes === 0) return { size: 0, unit: 'Bytes' };
  
    const units = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const k = 1024;
    const i = Math.floor(Math.log(bytes) / Math.log(k));
  
    return {
      size: parseFloat((bytes / Math.pow(k, i)).toFixed(2)),
      unit: units[i]
    };
  };
  