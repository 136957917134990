import React, { useState, useEffect, useContext } from 'react';
import { Button, TextField, FormControlLabel, Switch } from '@mui/material';
import { AuthContext } from '../../AuthContext';
import './AdminSettings.css';

const AdminSettings = () => {
    const [settings, setSettings] = useState({});
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const { authenticatedUser } = useContext(AuthContext);
    const token = localStorage.getItem("token");

    useEffect(() => {
        const fetchSettings = async () => {
            try {
                const response = await fetch('http://localhost:4050/admin/settings', {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                if (!response.ok) {
                    throw new Error('Error fetching settings');
                }
                const data = await response.json();
                setSettings(data);
            } catch (error) {
                setError(error.message);
            }
        };

        fetchSettings();
    }, [token]);

    const handleInputChange = (e) => {
        setSettings({ ...settings, [e.target.name]: e.target.value });
    };

    const handleSwitchChange = (e) => {
        setSettings({ ...settings, [e.target.name]: e.target.checked });
    };

    const handleSubmit = async () => {
        try {
            const response = await fetch('http://localhost:4050/admin/updatesettings', {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(settings)
            });
            if (!response.ok) {
                throw new Error('Error updating settings');
            }
            setSuccess('Settings updated successfully');
            setError('');
        } catch (error) {
            setError(error.message);
            setSuccess('');
        }
    };

    return (
        <div className='adminSettingsContainer'>
            <h1 className='dashboardTitle'>System Settings</h1>

            {error && <p className="error">{error}</p>}
            {success && <p className="success">{success}</p>}

            <div className="settingsForm">
                <TextField label="System Name" name="systemName" value={settings.systemName || ''} onChange={handleInputChange} />
                <TextField label="Institution Name" name="institutionName" value={settings.institutionName || ''} onChange={handleInputChange} />
                <TextField label="Support Email" name="supportEmail" value={settings.supportEmail || ''} onChange={handleInputChange} />
                <TextField label="General System Prompt" name="generalSystemPrompt" value={settings.generalSystemPrompt || ''} onChange={handleInputChange} />
                <TextField label="OpenAI API Key" name="openAIApiKey" value={settings.openAIApiKey || ''} onChange={handleInputChange} />

                <FormControlLabel control={<Switch checked={settings.useOpenAI || false} onChange={handleSwitchChange} name="useOpenAI" />} label="Use OpenAI" />
                <FormControlLabel control={<Switch checked={settings.enableConversationLogging || false} onChange={handleSwitchChange} name="enableConversationLogging" />} label="Enable Conversation Logging" />
                <FormControlLabel control={<Switch checked={settings.useActivationCodesForRegistration || false} onChange={handleSwitchChange} name="useActivationCodesForRegistration" />} label="Use Activation Codes For Registration" />
                <FormControlLabel control={<Switch checked={settings.userInactivityDeletion || false} onChange={handleSwitchChange} name="userInactivityDeletion" />} label="User Inactivity Deletion" />
                <TextField label="User Inactivity Deletion Time" name="userInactivityDeletionTime" type="number" value={settings.userInactivityDeletionTime || ''} onChange={handleInputChange} />
                
                <Button variant="contained" color="primary" onClick={handleSubmit}>
                    Save Changes
                </Button>
            </div>
        </div>
    );
}

export default AdminSettings;
