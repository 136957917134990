import React, { useContext } from 'react';
import './Dashboard.css';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../AuthContext'; 
import AdminDashboard from '../../components/AdminDashboard';
import Chat from '../../components/Chat';

const Dashboard = () => {
  const navigate = useNavigate();
  const { authenticatedUser } = useContext(AuthContext); 

  const isAdmin = authenticatedUser?.role === 'Admin';

  if (isAdmin) {
    return <AdminDashboard />;
  }

  return (
    <div className='dashboardContainer'>
      <Chat></Chat>
      <h1 className='dashboardTitle'>Dashboard</h1>

      <div className="cardsContainer">
        {/* Card 1 */}
        <div className="card" onClick={() => navigate('/drive/')}>
          <h2 className="cardTitle">My Files</h2>
        </div>

        {/* Card 2 */}
        <div className="card" onClick={() => navigate('/drive/?createFolder=true')}>
          <div className="iconContainer">
            <span className="plusIcon">+</span>
          </div>
          <p className="cardSubtitle">Create new chatbot</p>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
