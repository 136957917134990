import React, { useState, useEffect, useContext } from "react";
import "./AdminUserEdit.css";
import { AuthContext } from "../../AuthContext";
import { formatBytes, convertToBytes, formatBytesForUnit } from "../../functions/utils";

import {
  TextField,
  Button,
  IconButton,
  Select,
  InputLabel,
  MenuItem,
  FormControl,
  Box,
  Divider,
} from "@mui/material";

import SearchIcon from "@mui/icons-material/Search";
import PersonIcon from "@mui/icons-material/Person";
import EmailIcon from "@mui/icons-material/Email";
import LockPersonIcon from "@mui/icons-material/LockPerson";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import CloseIcon from "@mui/icons-material/Close";

const AdminUserEdit = () => {
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [adminPassword, setAdminPassword] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [pageLimit, setPageLimit] = useState(5);
  const [driveSize, setDriveSize] = useState(0);
  const [driveSizeUnit, setDriveSizeUnit] = useState("MB");

  const { authenticatedUser } = useContext(AuthContext);
  const token = localStorage.getItem("token");

  // Uncomment this so the settings diappear if the user clicks on the background

  // document.querySelector("body").addEventListener("click", (event) => {
  //   if (event.target.classList.contains("content")) setSelectedUser(null);
  // });

  const fetchUsers = async () => {
    try {
      const response = await fetch(
        `http://localhost:4050/admin/users?page=${currentPage}&limit=${pageLimit}&search=${searchTerm}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (!response.ok) {
        throw new Error("Error fetching users");
      }
      const data = await response.json();
      setUsers(data.data);
      setTotalPages(data.lastPage);
    } catch (error) {
      setError(error.message);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, [token, currentPage, searchTerm, pageLimit]);

  const handleSearchTermChange = (e) => {
    console.log("Event", e)
    setSelectedUser(null);
    setSearchTerm(e.target.value);
    setCurrentPage(1);
  };

  const goToNextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const goToPreviousPage = () => {
    setCurrentPage(currentPage - 1);
  };

  const handleSelectUser = (userId) => {
    const user = users.find((u) => u.id === userId);
    
    if (user) {
      const { size, unit } = formatBytesForUnit(user.driveSizeLimit);
      setDriveSize(size);
      setDriveSizeUnit(unit);
      setSelectedUser(user);
    }
  };
  
  const handleInputChange = (e, field) => {
    setSelectedUser({ ...selectedUser, [field]: e.target.value });
  };

  const handleSearchClick = () => {
    setCurrentPage(1);
    fetchUsers();
  };

  const handleSaveChanges = async () => {
    try {
      const driveSizeInBytes = convertToBytes(driveSize, driveSizeUnit);
      const response = await fetch(
        `http://localhost:4050/admin/users/${selectedUser.id}/settings`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`,
          },
          body: JSON.stringify({
            firstName: selectedUser.firstName,
            lastName: selectedUser.lastName,
            role: selectedUser.role,
            adminPassword,
            driveSizeLimit: driveSizeInBytes
          }),
        }
      );
      if (!response.ok) {
        throw new Error("Error updating user data");
      }

      setSuccess("User data updated successfully");
      setError("");

      // Close the edit form
      setSelectedUser(null);

      // Reset the success message after 10 seconds
      setTimeout(() => setSuccess(""), 10000);
    } catch (error) {
      setError(error.message);
      setSuccess("");

      // Reset the error message  after 10 seconds
      setTimeout(() => setError(""), 10000);
    }
  };

  return (
    <Box className="adminUserEditContainer">
      <h1>Edit Users</h1>
      <span>Click on a user to update the user settings.</span>

      {error && <p className="error">{error}</p>}
      {success && <p className="success">{success}</p>}

      <Box className="box">
        <div className="searchBar">
          <TextField
            type="text"
            value={searchTerm}
            onChange={(e) => handleSearchTermChange(e)}
            placeholder="Search users (name, email, id)"
            label="Search users"
            fullWidth={true}
          />

          <Button onClick={handleSearchClick} variant="contained">
            <SearchIcon />
            Search
          </Button>
        </div>

        <div className="listElement">
          <div className="listItem listHeader">
            <PersonIcon />
            Name
          </div>
          <div className="listItem listHeader">
            <EmailIcon />
            E-Mail
          </div>
          <div className="listItem listHeader">
            <LockPersonIcon />
            ID
          </div>
        </div>
        <Divider />

        {users.map((user, index) => (
          <div key={user.id} onClick={() => handleSelectUser(user.id)}>
            <div
              className={
                "listElement " +
                (authenticatedUser.id === user.id ? " highlightElement" : "")
              }
            >
              <div className="listItem">
                <PersonIcon />
                <span>
                  {user.firstName + " " + user.lastName}{" "}
                  {authenticatedUser.id === user.id && "(YOU)"}
                </span>
              </div>

              <div className="listItem">
                <EmailIcon />
                <span>{user.email}</span>
              </div>

              <div className="listItem">
                <LockPersonIcon />
                <span>{user.id}</span>
              </div>

              <div className="listItem">
                <span>Drive Limit: {formatBytes(user.driveSizeLimit)}</span>
              </div>
            </div>

            {/* Don't show a divider after the last element */}
            {index < users.length - 1 && <Divider />}
          </div>
        ))}
      </Box>

      {selectedUser && (
        <Box className="box userSettings">
          <div className="closeButtonContainer">
            <IconButton
              color="error"
              onClick={() => {
                setSelectedUser(null);
              }}
            >
              <CloseIcon />
            </IconButton>
          </div>
          <FormControl fullWidth>
            <TextField
              type="text"
              value={selectedUser.firstName}
              onChange={(e) => handleInputChange(e, "firstName")}
              placeholder="First Name"
              label="First name"
            />

            <TextField
              type="text"
              value={selectedUser.lastName}
              onChange={(e) => handleInputChange(e, "lastName")}
              placeholder="Last Name"
              label="Last name"
            />

            <FormControl fullWidth>
              <InputLabel id="userTypeSelection">User type</InputLabel>
              <Select
                id="userTypeSelection"
                value={selectedUser.role}
                onChange={(e) => handleInputChange(e, "role")}
                label="User type"
              >
                <MenuItem value="Admin">Admin</MenuItem>
                <MenuItem value="User">User</MenuItem>
              </Select>
            </FormControl>

            <TextField
              type="number"
              label="Drive Size"
              value={driveSize}
              onChange={(e) => setDriveSize(e.target.value)}
              placeholder="Enter Drive Size"
            />

            <FormControl fullWidth>
              <InputLabel id="driveSizeUnitSelection">Unit</InputLabel>
              <Select
                id="driveSizeUnitSelection"
                value={driveSizeUnit}
                onChange={(e) => setDriveSizeUnit(e.target.value)}
                label="Drive Size Unit"
              >
                <MenuItem value="MB">MB</MenuItem>
                <MenuItem value="GB">GB</MenuItem>
                <MenuItem value="TB">TB</MenuItem>
              </Select>
            </FormControl>

            <TextField
              label="Admin password"
              type="password"
              value={adminPassword}
              onChange={(e) => setAdminPassword(e.target.value)}
              placeholder="Enter your Admin Password to apply changes."
            />

            <Button
              variant="contained"
              onClick={handleSaveChanges}
              color="success"
              disabled={adminPassword === ""}
            >
              Save Changes
            </Button>
          </FormControl>
        </Box>
      )}

      <div className="pagination">
        <Button
          onClick={goToPreviousPage}
          disabled={currentPage === 1 && currentPage !== 0}
          startIcon={<ArrowBackIosNewIcon />}
        >
          Previous
        </Button>

        <span>
          Page {currentPage} of {totalPages}
        </span>

        <Button
          onClick={goToNextPage}
          disabled={currentPage === totalPages || totalPages === 0}
          endIcon={<ArrowForwardIosIcon />}
        >
          Next
        </Button>

        <FormControl>
          <InputLabel id="usersPerPage">Show users</InputLabel>
          <Select
            className="usersPerPage"
            labelId="usersPerPage"
            label="Show"
            value={pageLimit}
            onChange={(e) => setPageLimit(e.target.value)}
          >
            <MenuItem value={1}>1</MenuItem>
            <MenuItem value={5}>5</MenuItem>
            <MenuItem value={25}>25</MenuItem>
            <MenuItem value={50}>50</MenuItem>
            <MenuItem value={100}>100</MenuItem>
          </Select>
        </FormControl>
      </div>
    </Box>
  );
};

export default AdminUserEdit;
