import React from 'react';
import { Link } from 'react-router-dom';
import { useContext } from "react";
import { AuthContext } from '../AuthContext';

import './Navbar.css';

const Navbar = () => {

    const { authenticatedUser, setAuthenticatedUser } = useContext(AuthContext);

    const handleLogout = () => {
        // remove the token
        localStorage.removeItem("token");
        // unset the authenticated user
        setAuthenticatedUser(null);
    };

    return (
        <div>
            {authenticatedUser ? (
                <div>
                    <nav className="navbar-authed">
                        <Link to="/" className="logo">
                            <img src={`${process.env.PUBLIC_URL}/pet_logo_1.jpg`} alt="logo" />
                        </Link>
                        <div className="nav-links">
                            <Link to="/about" className="nav-item">Über uns</Link>
                            {/* add more nav links here */}
                        </div>
                        <div className="auth-buttons">
                            <Link to="/settings" className="nav-item">
                                <img id='cogwheel' src={`${process.env.PUBLIC_URL}/cogwheel.png`} alt="settings" />
                            </Link>
                            <button className="sign-in" onClick={handleLogout}>Logout</button>
                        </div>
                        <div className='userProfile'>

                        </div>
                    </nav>
                </div>
            ) : (
                <div>
                    <nav className="navbar">
                        <Link to="/" className="logo">
                            <img src={`${process.env.PUBLIC_URL}/pet_logo_1.jpg`} alt="logo" />
                        </Link>
                        <div className="nav-links">
                            <Link to="/about" className="nav-item">Über uns</Link>
                            {/* add more nav links here */}
                        </div>
                        <div className="auth-buttons">
                            <Link to="/signup" className="sign-up">Registrieren</Link>
                            <Link to="/login" className="sign-in">Login</Link>
                        </div>
                    </nav>
                </div>
            )}
        </div>
    );
}

export default Navbar;
