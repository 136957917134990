import React from 'react';
import './NotFound.css';

const NotFound = () => {
    return (
        <div className="notFoundContainer">
            <h1 className="notFoundTitle">
                Ups! Diese Seite hat wohl der PET gefressen...
            </h1>
        </div>
    );
}

export default NotFound;
