import React from 'react';
import './Footer.css';

const Footer = () => {
    return (
        <footer>
            <div className="footer">
                <p>Erstellt von <a target={'_blank'} href="https://uni-hohenheim.de/">Universität Hohenheim</a> im Rahmen des <a target={'_blank'} href="https://www.uni-hohenheim.de/projekt-dellfi">Dellfi Projekts</a> und gefördert von der <a target={'_blank'} href="https://stiftung-hochschullehre.de/foerderung/">Stiftung Innovation Hochschullehre</a> - Team: <a target={'_blank'} href="https://www.h-ka.de/die-hochschule-karlsruhe/organisation-personen/personen-a-z/person/matthias-woelfel">Matthias Wölfel</a> & <a target={'_blank'} href="https://mediennutzung.uni-hohenheim.de/en/andreas-reich">Andreas Reich</a></p>
                <p>Zuletzt Aktualisiert: 2024-09-01 <a href="mailto:andreas.reich@uni-hohenheim.de">Feedback & Bugs</a></p>
                <p>Version 2.1.0.1</p>
            </div>
        </footer>
    );
};

export default Footer;