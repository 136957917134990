import React from 'react';
import "./Landing.css";
import { Link } from 'react-router-dom';

const Landing = () => {
  return (
    <div className='landing'>
    <div className='backgroundContainer'>
      <div className='light-animation'></div>
      <div className='light-animation-blue'></div>
      <h1 className='backgroundTitle'>Die Zukunft ist jetzt.</h1>
      <p className='text'>Jetzt den PET (PEdagogical conversational Tutor) für deine Vorlesung ausprobieren.</p>
        <Link className='sign-up-big' to="/signup" >Jetzt Loslegen</Link>
    </div>
    </div>
  );
}

export default Landing;
