import React, { useState, useEffect, useRef } from 'react';
import './IntegrationManager.css';

const ConfirmationModal = ({ onConfirm, onCancel, message }) => {
  return (
    <div className="confirmationModalOverlay">
      <div className="confirmationModal">
        <p>{message}</p>
        <button onClick={onConfirm}>Yes</button>
        <button onClick={onCancel}>No</button>
      </div>
    </div>
  );
};

const IntegrationModal = ({ onClose }) => {
  const [integrations, setIntegrations] = useState([
    { id: 'googleDrive', name: 'Google Drive', connections: [] },
    { id: 'oneDrive', name: 'OneDrive', connections: [] },
    { id: 'dropbox', name: 'Dropbox', connections: [] },
    // Add more integrations as needed
  ]);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [integrationToRemove, setIntegrationToRemove] = useState(null);
  const modalContentRef = useRef(null);


  const handleAddIntegration = (integrationId) => {
    // Simulate adding an integration
    // In a real scenario, you would initiate the OAuth process here
    console.log(`Adding integration: ${integrationId}`);
    // After successful OAuth flow, update the state to reflect the new connection
  };

  const handleRemoveIntegration = (integrationId) => {
    // Update integrations state to remove the specified connection
    console.log(`Removing integration: ${integrationId}`);
    setShowConfirmationModal(false);
    // Perform any additional cleanup or state updates needed
  };

  const handleOpenConfirmationModal = (integrationId) => {
    setIntegrationToRemove(integrationId);
    setShowConfirmationModal(true);
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (modalContentRef.current && !modalContentRef.current.contains(event.target)) {
        onClose(); // Close modal
      }
    }

    // Add and remove event listener for click
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [modalContentRef, onClose]);

  return (
    <div className="integrationModalOverlay">
      <button onClick={onClose} className="closeModalButton">&#215;</button>
      <div className="integrationModal" ref={modalContentRef}>
        <h2>Integrate External Data Sources</h2>
        {integrations.map((integration) => (
          <div key={integration.id} className={`integrationCard ${integration.connections.length > 0 ? '' : 'greyedOut'}`}>
            <img src={`/integration_logos/${integration.id}.png`} alt={`${integration.name} logo`} />
            <h4>{integration.name}</h4>
            {integration.connections.length > 0 ? (
              integration.connections.map((connection, index) => (
                <div key={index}>
                  <p>{connection.name}</p>
                  <button onClick={() => handleOpenConfirmationModal(integration.id)}>Remove</button>
                </div>
              ))
            ) : (
              <button onClick={() => handleAddIntegration(integration.id)}>Connect</button>
            )}
          </div>
        ))}
        {showConfirmationModal && (
          <ConfirmationModal
            onConfirm={() => handleRemoveIntegration(integrationToRemove)}
            onCancel={() => setShowConfirmationModal(false)}
            message="Are you sure you want to remove this integration?"
          />
        )}
      </div>
    </div>
  );
};

const IntegrationManager = () => {
  const [isModalOpen, setModalOpen] = useState(false);

  return (
    <>
      <button className="addDataSourceButton" onClick={() => setModalOpen(true)}>
        Add External Data Source
      </button>
      {isModalOpen && <IntegrationModal onClose={() => setModalOpen(false)} />}
    </>
  );
};

export default IntegrationManager;
