import "./App.css";
import React from "react";
import { BrowserRouter as Router, Routes, Route, useNavigate } from "react-router-dom";
import Landing from "./pages/public/Landing";
import About from "./pages/public/About";
import Dashboard from "./pages/authenticated/Dashboard";
import NotFound from "./pages/public/NotFound";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import SignUpLogin from "./pages/public/SignUpLogin";
import Settings from "./pages/authenticated/Settings";
import Drive from "./pages/authenticated/Drive";
import AdminUserEdit from "./pages/authenticated/AdminUserEdit";
import AdminSettings from "./pages/authenticated/AdminSettings";

import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

import { useContext, useEffect } from "react";
import { AuthContext } from "./AuthContext";

import { createTheme, ThemeProvider } from "@mui/material";

function VerifyToken() {
  const { setAuthenticatedUser } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token");

    if (token) {
      fetch("http://localhost:4050/verify-token", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ token }),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.message === "Token verification succeeded.") {
            // token is still good, we can leave the user logged in
            setAuthenticatedUser(data.user);
          } else {
            // the token is not valid, so we need to log the user out
            localStorage.removeItem("token");
            setAuthenticatedUser(null);
            navigate("/login");
          }
        })
        .catch((error) => {
          console.error("Error:", error);
          // token might not be valid, so we should log the user out just in case
          localStorage.removeItem("token");
          setAuthenticatedUser(null);
          navigate("/login");
        });
    }
  }, [navigate, setAuthenticatedUser]);

  return null;
}

function RedirectToHomeIfAuthenticated({ children }) {
  const { authenticatedUser } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (authenticatedUser) {
      navigate("/");
    }
  }, [authenticatedUser, navigate]);

  return children;
}

function App() {
  const { authenticatedUser } = useContext(AuthContext);

  const theme = createTheme({});

  //Admin Routes are hidden for now except for the drive.
  /*
          <Route path="/adminuseredit" element={authenticatedUser && authenticatedUser?.role === 'Admin' ? <AdminUserEdit /> : <SignUpLogin />} />
          <Route path="/adminsettings" element={authenticatedUser && authenticatedUser?.role === 'Admin' ? <AdminSettings /> : <SignUpLogin />} />
  */

  return (
    <ThemeProvider theme={theme}>
      <DndProvider backend={HTML5Backend}>
        <Router>
          <Navbar />
          <VerifyToken />
          <Routes>
            <Route path="/" element={authenticatedUser ? <Dashboard /> : <Landing />} />
            <Route path="/about" element={<About />} />
            <Route
              path="/login"
              element={
                !authenticatedUser ? <SignUpLogin /> : <RedirectToHomeIfAuthenticated />
              }
            />
            <Route
              path="/signup"
              element={
                !authenticatedUser ? <SignUpLogin /> : <RedirectToHomeIfAuthenticated />
              }
            />
            <Route
              path="/settings"
              element={authenticatedUser ? <Settings /> : <SignUpLogin />}
            />
            <Route
              path="/drive/*"
              element={authenticatedUser ? <Drive /> : <SignUpLogin />}
            />
            <Route path="*" element={<NotFound />} />
          </Routes>
          <Footer />
        </Router>
      </DndProvider>
    </ThemeProvider>
  );
}

export default App;
