import React, { useState, useEffect, useRef } from 'react';
import { formatBytes } from '../../functions/utils';
import FileUploader from '../../components/FileUploader';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import './Drive.css';
import IntegrationManager from '../../components/IntegrationManager';  
import FileViewer from '../../components/FileViewer';
import { useDrag, useDrop } from 'react-dnd';
import Chat from '../../components/Chat';
import { Box } from '@mui/material';
import StorageBar from '../../components/StorageBar';

const stripLastPartOfPath = (path) => {
    if (!path || path === "/") return "";

    const parts = path.split("/");
    parts.pop();

    if (parts.length === 1 && parts[0] === "") return "";

    return parts.join("/");
}

function isValidFolderName(folderName) {
    const invalidChars = /[<>:"/\\|?*\x00-\x1F]/;
    const pathTraversal = /(\.\.[/\\])/;

    if (!folderName.trim().length) {
        return false;
    }

    return !invalidChars.test(folderName) && !pathTraversal.test(folderName);
}

const getBaseName = (filename) => {
    const lastDot = filename.lastIndexOf('.');
    if (lastDot === -1) return filename; // No extension found
    return filename.substring(0, lastDot);
};

function sanitizeFolderName(folderName) {
    // Trim spaces from the beginning and end
    let sanitized = folderName.trim();
    // Replace multiple spaces with a single space
    sanitized = sanitized.replace(/\s+/g, ' ');
    return sanitized;
}

function sanitizeDecodeFolderName(folderName) {
    // URL decode the folderName
    const decodedName = decodeURIComponent(folderName);

    // Trim spaces from the beginning and end
    let sanitized = decodedName.trim();
    // Replace multiple spaces with a single space
    sanitized = sanitized.replace(/\s+/g, ' ');

    return sanitized;
}

function FolderItem({
    name, fullPath, subFolders,
    onClick, isExpandable, onExpand,
    onNavigate, moveItems, currentPath, depth = 0
}) {
    const [isExpanded, setIsExpanded] = useState(false);
    const [{ isOver }, drop] = useDrop({
        accept: 'FILE_ITEM',
        drop: (item, monitor) => {
            if (item.type === 'directory' && item.fullPath === `${currentPath}/${name}`) {
                console.log('Cannot drop a folder into itself');
                return;
            }
            console.log("current path:",fullPath);

            const itemsToMove = [item.fullPath];
            moveItems(itemsToMove, fullPath);
        },
        collect: monitor => ({
            isOver: !!monitor.isOver(),
        }),
    });

    const handleFolderClick = (e) => {
        e.stopPropagation(); 
        console.log("Navigating to:", fullPath);
        if (onNavigate) {
            onNavigate(fullPath, false);
        }
        if (onClick) {
            onClick();
        }
    };

    const handleExpandClick = (e) => {
        e.stopPropagation();

        setIsExpanded(!isExpanded);
        if (onExpand) {
            onExpand(name, !isExpanded);
        }
    };

    const backgroundColor = isOver ? 'lightgray' : 'transparent';

    // Don't render the folder if it has no subfolders and is not expandable - minio shenannegans
    if (!subFolders || subFolders.length === 0 || name.trim() === "") {
        return null;
    }

    return (
        <div className="folderItem" style={{ marginLeft: '10px' }} onClick={handleFolderClick}>
            <span
                className={`expandCollapseSymbol ${isExpandable ? '' : 'invisible'}`}
                onClick={handleExpandClick}
            >
                {isExpanded ? "v" : ">"}
            </span>
            <span ref={drop} style={{ backgroundColor }}onClick={onClick}>
                📁 {name && name.length > 20 ? name.slice(0, 20) + "..." : name}
            </span>
            {isExpanded && <FolderList folders={subFolders} onNavigate={onNavigate} parentPath={fullPath} moveItems={moveItems} currentPath={currentPath} depth={depth + 1} />}
        </div>
    );
}

function FolderList({ folders, onNavigate, parentPath = "", moveItems, currentPath, depth = 1 }) {
    const [expandedFolders, setExpandedFolders] = useState([]);

    const handleExpand = (folderName, expand) => {
        if (expand) {
            setExpandedFolders([...expandedFolders, folderName]);
        } else {
            setExpandedFolders(
                expandedFolders.filter(name => name !== folderName)
            );
        }
    };

    return (
        <div className="folderList">
            {Object.entries(folders).map(([folderName, subFolders]) => (
                <FolderItem
                    key={folderName}
                    name={folderName}
                    depth={depth}
                    fullPath={`${parentPath}/${folderName}`}
                    subFolders={subFolders}
                    onNavigate={onNavigate}
                    isExpandable={Object.keys(subFolders).length > 0}
                    onExpand={handleExpand}
                    moveItems={moveItems} 
                    currentPath={currentPath}
                />
            ))}
        </div>
    );
}

function FilterBar({ sortField, sortDirection, onSortChange }) {
    const handleSortChange = (field) => {
        if (field === sortField) {
            onSortChange(field, sortDirection === 'asc' ? 'desc' : 'asc');
        } else {
            onSortChange(field, 'asc');
        }
    }

    return (
        <div className="filterBar">
            <span className="filterBarItem">Filter by:   </span>
            <button className="filterBarItem" onClick={() => handleSortChange('name')}>Name {sortField === 'name' && (sortDirection === 'asc' ? '↓' : '↑')}</button>
            <button className="filterBarItem" onClick={() => handleSortChange('size')}>Size {sortField === 'size' && (sortDirection === 'asc' ? '↓' : '↑')}</button>
            <button className="filterBarItem" onClick={() => handleSortChange('modifiedDate')}>Date {sortField === 'modifiedDate' && (sortDirection === 'asc' ? '↓' : '↑')}</button>
        </div>
    );
}


function DriveBar({ metadata }) {
    return (
        <div className="driveBar">
            <p className="item left">Total Size: {formatBytes(metadata.totalSize)}
                <span className="pptx-info" onMouseOver={(e) => e.stopPropagation()} onMouseOut={(e) => e.stopPropagation()}>
                    ℹ️
                    <span className="pptx-tooltip">We also store preview files. This is why you might see more storage used than you uploaded.</span>
                </span>
            </p>
            <p className="item center">Number of Files: {metadata.numOfFiles || 0}
                <span className="pptx-info" onMouseOver={(e) => e.stopPropagation()} onMouseOut={(e) => e.stopPropagation()}>
                    ℹ️
                    <span className="pptx-tooltip">We also store preview files. This is why you might see more files here than you uploaded.</span>
                </span>
            </p>
            <p className="item right">Number of Directories: {metadata.numOfDirectories || 0}</p>

        </div>
    );
}

function FileItem({
    name, type, size, modifiedDate,
    onFileClick, onDirClick, onDelete,
    onDownload, onRename, moveItems, currentPath, token
}) {
    const [fileStatus, setFileStatus] = useState(null);
    const intervalRef = useRef(null);
    const [isProcessing, setIsProcessing] = useState(true);

    useEffect(() => {
        if (type !== "directory") {
            const fetchFileStatus = async () => {
                // console.log("Trying to fetch file Status. Current fileStatus", fileStatus);
                //console.log("File Name", name);
                //console.log("Current Path", currentPath);
                let name_with_path = name;
                if (currentPath != ""){
                    name_with_path = currentPath + "/" + name;
                    name_with_path = name_with_path.replace("%20", " ");
                }
                try {
                    const response = await fetch('http://localhost:4050/api/file_status', {
                        method: 'POST',
                        headers: { 
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${token}`
                        },
                        body: JSON.stringify({ file_name: name_with_path })
                    });
                    if (!response.ok) throw new Error('Failed to fetch file status.');
                    const data = await response.json();
                    const { has_been_processed, is_updating } = data;
                    if(has_been_processed == true){
                        setIsProcessing(false);
                        setFileStatus({ has_been_processed, is_updating });
                    }

                    // Stop requests if the file has been processed
                    if (has_been_processed && intervalRef.current) {
                        clearInterval(intervalRef.current);
                        intervalRef.current = null;
                    }
                } catch (error) {
                    //console.error("Error fetching file status:", error);
                }
            };

            fetchFileStatus();

            if (!fileStatus || (fileStatus && !fileStatus.has_been_processed)) {
                intervalRef.current = setInterval(fetchFileStatus, 1500);
            }

            return () => clearInterval(intervalRef.current);
        }
    }, [name, token]);

    useEffect(() => {
        if (fileStatus && fileStatus.has_been_processed && intervalRef.current) {
            clearInterval(intervalRef.current);
            intervalRef.current = null;
        }
    }, [fileStatus]);

    const [{ isDragging }, drag, dragPreview] = useDrag({
        type: 'FILE_ITEM',
        item: {
            name: name,
            type: type,
            fullPath: `${currentPath}/${name}`
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    const [{ isOver }, drop] = useDrop({
        accept: 'FILE_ITEM',
        drop: (item, monitor) => {
            const draggedFullPath = item.fullPath;
            const targetFullPath = `${currentPath}/${name}`;

            // Prevent dropping into a file (drop only into directories)
            if (type !== 'directory') {
                console.log('Cannot drop into a file, only directories can accept items');
                return;
            }

            // Check if the target is a folder, and if the dragged item is being dropped into itself
            if (item.type === 'directory' && draggedFullPath === targetFullPath) {
                console.log('Cannot drop a folder into itself');
                return;
            }

            // Prevent files from being dragged into the same folder
            if (item.type !== 'directory' && draggedFullPath === targetFullPath) {
                console.log('Cannot drop a file into itself');
                return;
            }

            // Handle the drop action for moving items
            console.log("current path:", currentPath);
            const itemsToMove = [draggedFullPath];
            moveItems(itemsToMove, targetFullPath);
        },
        collect: monitor => ({
            isOver: !!monitor.isOver(),
        }),
    });

    const handleClick = (e) => {
        if (e.defaultPrevented) return;

        if (type === "directory") {
            onDirClick();
        } else {
            onFileClick();
        }
    }

    const backgroundColor = isOver ? 'lightgray' : 'transparent';

    return (
        <div
            ref={node => dragPreview(drop(node))}
            className="fileItem"
            onClick={handleClick}
            style={{ opacity: isDragging ? 0.5 : 1 }}
        >
            <div ref={drag} style={{ backgroundColor }} className='fileName'>
                {type === "directory" ? "📁  " : "🗎  "} {name}
            </div>
            {type !== "directory" && (
                <div className='fileItemText'>
                    {isProcessing && (
                        <span className="spinner-container">
                            <span className="spinner-text">Processing File</span>
                            <span className="spinner">⛏️</span>
                        </span>
                    )}

                    {!isProcessing && fileStatus.has_been_processed && (
                        <span className="checkmark">✔️</span>
                    )}
                    {name.includes(".pptx") === true && (
                        <span className="pptx-info" onMouseOver={(e) => e.stopPropagation()} onMouseOut={(e) => e.stopPropagation()}>
                            ℹ️
                            <span className="pptx-tooltip">We convert pptx files to pdf files for previewing. Therefore we store two files per pptx file.</span>
                        </span>
                    )}
                    <span className="size-display">{formatBytes(size)}</span>
                    <span className="modified-date">edited: {new Date(modifiedDate).toLocaleDateString()}</span>
                    <span className="download-icon" onClick={(e) => { e.stopPropagation(); onDownload(name); }}>
                        ⬇
                    </span>
                </div>
            )}
            {
                type === "directory" ? <span className="edit-icon-auto-margin" onClick={(e) => { e.stopPropagation(); onRename(name, type); }}>
                    🖊️
                </span> : <span className="edit-icon" onClick={(e) => { e.stopPropagation(); onRename(name, type); }}>
                    🖊️
                </span>
            }
            <span className="delete-icon" onClick={(e) => { e.stopPropagation(); onDelete(name); }}>
                X
            </span>
        </div>
    );
}

function FileList({ 
    files, onNavigate, currentPath, 
    deleteFile, downloadFile, setItemToRename, 
    setIsRenameModalOpen, moveItems, token
}) {
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [itemToDelete, setItemToDelete] = useState(null);

    const handleDelete = (itemName, itemType) => {
        setItemToDelete({ name: itemName, type: itemType });
        setShowConfirmModal(true);
    };

    const confirmDelete = () => {
        console.log("Deleting:", itemToDelete);
        deleteFile(itemToDelete);
        setShowConfirmModal(false);
        setItemToDelete(null);
    };

    const [{ isOver }, drop] = useDrop({
        accept: 'FILE_ITEM',
        drop: (item, monitor) => {
            if (item.type === 'directory' && item.fullPath === `${stripLastPartOfPath(currentPath)}`) {
                console.log('Cannot drop a folder into itself');
                return;
            }
            console.log("current path:",currentPath);
            const itemsToMove = [item.fullPath];
            const targetFolder = `${stripLastPartOfPath(currentPath)}`;
            moveItems(itemsToMove, targetFolder);
        },
        collect: monitor => ({
            isOver: !!monitor.isOver(),
        }),
    });

    const backgroundColor = isOver ? 'lightgray' : 'transparent';

    // Filter out .keep files before rendering
    const filteredFiles = files.filter(file => !file.name.includes('.keep'));

    return (
        <div className="fileList">
            {currentPath && (
                <div ref={drop} style={{ backgroundColor }} type="back" id='back' className="fileItem" onClick={() => onNavigate("..")}>
                    Back to previous folder
                </div>
            )}
            {filteredFiles.length === 0 && (
                <div className="fileItem">
                    No files in this folder yet.
                </div>
            )}
            {filteredFiles.map(file => (
                <FileItem
                    key={file.name}
                    name={file.name}
                    type={file.type}
                    size={file.size}
                    modifiedDate={file.modifiedDate}
                    onFileClick={() => downloadFile(file.name, true)}
                    onDirClick={() => onNavigate(file.name)}
                    onDelete={() => handleDelete(file.name, file.type)}
                    onDownload={downloadFile}
                    onRename={(name, type) => {
                        setItemToRename({
                            name,
                            baseName: getBaseName(name),
                            type
                        });
                        setIsRenameModalOpen(true);
                    }}
                    moveItems={moveItems} 
                    currentPath={currentPath}
                    token={token}
                />
            ))}
            <ConfirmModal
                isOpen={showConfirmModal}
                onClose={() => setShowConfirmModal(false)}
                onConfirm={confirmDelete}
                itemToDelete={itemToDelete}
            />
        </div>
    );
}

function ConfirmModal({ isOpen, onClose, onConfirm, itemToDelete }) {
    if (!isOpen) return null;
    let message = "Are you sure you want to delete this? This action is permanent and cannot be undone.";
    if (itemToDelete && itemToDelete.type === "directory") {
        message = "Are you sure you want to delete this folder? All contents of this folder WILL BE LOST! This action is permanent and cannot be undone.";
    } else if (itemToDelete && itemToDelete.type !== "directory") {
        message = "Are you sure you want to delete this file? This action is permanent and cannot be undone.";
    }
    return (
        <div className="modal">
            <div className="modal-content">
                <p>{message}</p>
                <button className='red' onClick={onConfirm}>Delete</button>
                <button className='green' onClick={onClose}>Cancel</button>
            </div>
        </div>
    );
}

function NewFolderModal({ isOpen, onClose, onCreate }) {
    const [folderName, setFolderName] = useState("");
    const [validationError, setValidationError] = useState(null);

    const handleSubmit = () => {
        if (isValidFolderName(folderName)) {
            onCreate(folderName);
            setFolderName("");
            setValidationError(null);
        } else {
            setValidationError("Invalid folder name.");
            console.log("Could not create folder");
        }
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleSubmit();
        }
    };

    useEffect(() => {
        if (validationError) {
            const timer = setTimeout(() => setValidationError(null), 5000);
            return () => clearTimeout(timer);
        }
    }, [validationError]);


    return isOpen ? (
        <div className="modal">
            <h3>Create a new folder</h3>
            <div className="modal-content">
                <input
                    value={folderName}
                    onChange={e => setFolderName(e.target.value)}
                    placeholder="Folder Name"
                    onKeyDown={handleKeyDown}
                />
                <div className="error-container">
                    {validationError && <p style={{ color: "red" }}>{validationError}</p>}
                </div>
                <button onClick={handleSubmit}>Create</button>
                <button onClick={onClose}>Close</button>
            </div>
        </div>
    ) : null;
}

function RenameModal({ isOpen, onClose, onRename, initialName }) {
    const [newName, setNewName] = useState(initialName || "");
    const [validationError, setValidationError] = useState(null);

    useEffect(() => {
        setNewName(initialName);
    }, [initialName]);    

    const handleSubmit = () => {
        if (newName === initialName) {
            setValidationError("The name hasn't been changed.");
        } else if (!isValidFolderName(newName)) {
            setValidationError("Invalid name.");
        } else {
            onRename(newName);
            setNewName("");
            setValidationError(null);
        }
    };
    

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleSubmit();
        }
    };

    useEffect(() => {
        if (validationError) {
            const timer = setTimeout(() => setValidationError(null), 5000);
            return () => clearTimeout(timer);
        }
    }, [validationError]);

    return isOpen ? (
        <div className="modal">
            <h3>Rename Item</h3>
            <div className="modal-content">
                <input
                    value={newName}
                    onChange={e => setNewName(e.target.value)}
                    placeholder="New Name"
                    onKeyDown={handleKeyDown}
                />
                <div className="error-container">
                    {validationError && <p style={{ color: "red" }}>{validationError}</p>}
                </div>
                <button onClick={handleSubmit}>Rename</button>
                <button onClick={onClose}>Close</button>
            </div>
        </div>
    ) : null;
}

const Drive = () => {

    const { path: urlPath } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const shouldOpenCreateFolderModal = queryParams.get('createFolder') === 'true';
    const [currentPath, setCurrentPath] = useState(urlPath || "");
    //console.log("Current Path", currentPath);
    const [lastValidPath, setLastValidPath] = useState("");
    const [error, setError] = useState(null);
    const [files, setFiles] = useState([]);
    const [metadata, setMetadata] = useState({
        totalSize: 0,
        numOfFiles: 0,
        numOfDirectories: 0
    });
    const [isModalOpen, setModalOpen] = useState(shouldOpenCreateFolderModal);
    const token = localStorage.getItem("token");
    const errorRef = useRef(false);
    const [previewedFile, setPreviewedFile] = useState(null);
    const [previewedFileName, setPreviewedFileName] = useState(null);
    const [previewedFileType, setPreviewedFileType] = useState(null);

    const [nameFilter, setNameFilter] = useState('');
    const [typeFilter, setTypeFilter] = useState('');
    const [dateFilter, setDateFilter] = useState(null);
    const [sizeFilter, setSizeFilter] = useState(null);
    const [sortField, setSortField] = useState('name');
    const [sortDirection, setSortDirection] = useState('asc');

    const [isRenameModalOpen, setIsRenameModalOpen] = useState(false);
    const [itemToRename, setItemToRename] = useState(null);
    const [folderStructure, setFolderStructure] = useState({});

    const [refreshTrigger, setRefreshTrigger] = useState(0);

    // Whenever metadata changes, update refreshTrigger
    useEffect(() => {
        setRefreshTrigger((prev) => prev + 1);
    }, [metadata]);

    const filteredAndSortedFiles = files
    .filter(file => {
        let valid = true;
        
        if (nameFilter) {
            valid = file.name.toLowerCase().includes(nameFilter.toLowerCase());
        }
        if (typeFilter && valid) {
            valid = file.type === typeFilter;
        }
        if (dateFilter && valid) {
            valid = new Date(file.modifiedDate).getTime() === new Date(dateFilter).getTime(); 
        }
        if (sizeFilter && valid) {
            valid = file.size <= sizeFilter; 
        }
    
        return valid;
    })
    .sort((a, b) => {
        let compareResult;
        
        if (a.type === 'directory' && b.type !== 'directory') return -1;
        if (a.type !== 'directory' && b.type === 'directory') return 1;
    
        switch (sortField) {
            case 'name':
                compareResult = a.name.localeCompare(b.name);
                break;
            case 'size':
                compareResult = a.size - b.size;
                break;
            case 'modifiedDate':
                compareResult = new Date(a.modifiedDate) - new Date(b.modifiedDate);
                break;
            default:
                compareResult = 0;
        }
    
        if (sortDirection === 'desc') {
            compareResult = -compareResult;
        }
    
        return compareResult;
    });


    const fetchFiles = async () => {
        try {
            const url = `http://localhost:4050/list?path=${currentPath}`;
            console.log("Fetching files from:", url);
            const response = await fetch(url, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            if (!response.ok) {
                throw new Error(`The folder you requested doesn't exist.`);
            }
            const data = await response.json();
            setFiles(data.items);
            console.log("Files Recieved", data.items);
            setLastValidPath(currentPath);
        } catch (error) {
            console.log(error);
            errorRef.current = true;
            error = true;
            handleNavigate('', false, true);
            setCurrentPath("");
            setError("The folder you requested doesn't exist.");
        }
    };

    const fetchMetadata = async () => {
        try {
            const url = `http://localhost:4050/metadata?path=${currentPath}`;
            console.log("Fetching metadata from:", url);
            const response = await fetch(url, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            if (!response.ok) {
                throw new Error(`The folder you requested doesn't exist.`);
            }
            const data = await response.json();
            setMetadata(data);
            setLastValidPath(currentPath);
        } catch (error) {
            console.log(error);
            errorRef.current = true;
            error = true;
            handleNavigate('', false, true);
            setCurrentPath("");
            setError("The folder you requested doesn't exist.");
        }
    };

    useEffect(() => {
        const fetchFolderStructure = async () => {
          const url = `http://localhost:4050/get-folder-structure`;
          const response = await fetch(url, {
            headers: {
              'Authorization': `Bearer ${token}`
            }
          });
          if (!response.ok) {
            throw new Error(`Failed to fetch folder structure.`);
          }
          const data = await response.json();
          console.log("Received data:", data);
          setFolderStructure(data["My Files"] || {});
        };
      
        fetchFolderStructure();
      }, [token, currentPath, files]);

    useEffect(() => {
        const newPath = location.pathname.replace("/drive/", "").replace("/drive", "");
        setCurrentPath(newPath);
    }, [location.pathname]);


    useEffect(() => {
        const fetchContent = async () => {
            try {
                await fetchFiles();
                setLastValidPath(currentPath);
            } catch (error) {
                console.error("Error fetching files:", error.message);
                setError(error.message);
            }
            try {
                await fetchMetadata();
                setLastValidPath(currentPath);
            } catch (error) {
                console.error("Error fetching metadata:", error.message);
                setError(error.message);
            }
        };
        if (currentPath !== lastValidPath || location.pathname.replace("/drive/", "").replace("/drive", "") == "") {
            fetchContent();
        }
    }, [currentPath, token]);


    useEffect(() => {
        if (error) {
            handleNavigate('', false, true);
        }
    }, [error]);


    const handleFilesUploaded = () => {
        fetchFiles().then(fetchMetadata);
    };

    useEffect(() => {
        if (error) {
            const timer = setTimeout(() => setError(null), 5000);
            return () => clearTimeout(timer);
        }
    }, [error]);

    const handleNavigate = (path, addpath = true, replace = false) => {
        let newPath = currentPath;

        if (path === "..") {
            newPath = currentPath.split("/").slice(0, -1).join("/");
        } else if (path) {
            if (addpath)
                // Remove trailing slashes from currentPath and leading slashes from path
                newPath = `${currentPath.replace(/\/+$/, '')}/${path.replace(/^\/+/, '')}`;
            else
                newPath = path;
        }

        // Remove any trailing or leading slashes:
        newPath = newPath.replace(/^\/+|\/+$/g, "");
        navigate("/drive/" + newPath, replace);
    };

    const moveItems = async (itemsToMove, targetFolder) => {
        try {
            console.log("Drag Drop Event: Triying to move items to", targetFolder);
            console.log("Drag Drop Event: Triying to move items", itemsToMove);
            await fetch('http://localhost:4050/move', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify({
                    itemsToMove,
                    targetFolder
                }),
            });
            fetchFiles();
        } catch (error) {
            console.error('Error moving items:', error);
        }
    }


    const handleCreateFolder = async (folderName) => {
        try {
            folderName = sanitizeFolderName(folderName);
            console.log("Requesting Folder Creation", currentPath + "/" + folderName, "FolderName", folderName);
            await fetch(`http://localhost:4050/create-folder`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ path: currentPath, folderName })
            });
            handleNavigate(folderName);
            setModalOpen(false);
        } catch (error) {
            console.log(error);
            setError(error.message);
        }
    };

    const deleteFile = async (fileName) => {
        try {
            const url = `http://localhost:4050/delete-file?path=${currentPath}&fileName=${fileName.name}`;
            console.log("Deleting file using:", url);
            await fetch(url, {
                method: "DELETE",
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            fetchFiles().then(fetchMetadata);
        } catch (error) {
            console.log(error);
            setError("Error deleting the file.");
        }
    };

    const downloadFile = async (fileName, inline = false) => {
        try {
            // Determine if the file is a .pptx
            const isPPTX = fileName.endsWith('.pptx');

            // If it's a PPTX file and we're trying to preview it, adjust the filename to its PDF counterpart
            const targetFileName = inline && isPPTX ? fileName.replace('.pptx', '-converted.pdf') : fileName;

            // Constructing the unified path
            const unifiedFilePath = currentPath ? `${currentPath}/${targetFileName}` : targetFileName;

            let url = '';
            if (inline) {
                url = `http://localhost:4050/download?filepath=${unifiedFilePath}&mode=inline`;
            } else {
                url = `http://localhost:4050/download?filepath=${unifiedFilePath}`;
            }

            const response = await fetch(url, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });

            if (!response.ok) {
                throw new Error('Failed to download the file.');
            }

            const blob = await response.blob();

            if (inline) {
                setPreviewedFile(blob);
                setPreviewedFileName(targetFileName);

                const fileType = fileName.split('.').pop();
                setPreviewedFileType(fileType);
            } else {
                const blobUrl = window.URL.createObjectURL(blob);

                const link = document.createElement('a');
                link.href = blobUrl;
                link.download = fileName; // Keep the original name for downloading
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        } catch (error) {
            console.error("Error downloading the file:", error);
        }
    };

    const handleRename = async (newName) => {
        try {
            const url = `http://localhost:4050/rename`;
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    path: currentPath,
                    oldName: itemToRename.name,
                    newName: `${newName}${itemToRename.type !== "directory" ? itemToRename.name.slice(itemToRename.name.lastIndexOf('.')) : ''}`
                }),
            });

            if (!response.ok) {
                throw new Error('Error renaming the item.');
            }
            
            fetchFiles();  // Refresh the file list
            setIsRenameModalOpen(false);
            setItemToRename(null);
            
        } catch (error) {
            console.log(error);
        }
    };    

    return (
        <div className="driveWrapper">
            <Chat></Chat>
            <div className="sidebar">
                <h3 id="sidebar-title"></h3>
                <button id='new-folder' onClick={() => setModalOpen(true)}>Create New Folder</button>
                <FolderItem 
                onClick={() => handleNavigate("/", false)} 
                name="My Files" fullPath="" 
                subFolders={folderStructure} 
                isExpandable={true} 
                onNavigate={handleNavigate} 
                moveItems={moveItems} 
                currentPath={currentPath}  />

                <IntegrationManager></IntegrationManager>
                <Box>
                    <StorageBar refreshTrigger={refreshTrigger} />
                </Box>
            </div>
            {previewedFile && (
                <div className="filePreviewContainer">
                    <FileViewer 
                    fileType={previewedFileType} 
                    file={previewedFile} 
                    fileName={previewedFileName} 
                    setPreviewedFile={setPreviewedFile} 
                    onDownload={downloadFile} />
                </div>
            )}
            <div className="driveContainer">
                <h1 className="driveTitle">{sanitizeDecodeFolderName(currentPath) || "My Files"}</h1>
                {error && <div className="error">{error}</div>}
                <div className="breadcrumb">
                    <span onClick={() => handleNavigate("/", false)}>
                        My Files
                    </span>
                    {currentPath && <span className="breadcrumb-separator">&gt;</span>}
                    {currentPath.split("/").filter(part => part).map((part, index, array) => {
                        const fullPath = array.slice(0, index + 1).join("/");
                        return (
                            <React.Fragment key={index}>
                                {index !== array.length - 1 ? (
                                    <span onClick={() => handleNavigate(fullPath, false)}>
                                        {sanitizeDecodeFolderName(part)}
                                    </span>
                                ) : (
                                    <span>{sanitizeDecodeFolderName(part)}</span>
                                )}
                                {index !== array.length - 1 && <span className="breadcrumb-separator">&gt;</span>}
                            </React.Fragment>
                        );
                    })}
                </div>

                <DriveBar metadata={metadata} />
                <FilterBar
                    sortField={sortField}
                    sortDirection={sortDirection}
                    onSortChange={(field, direction) => {
                        setSortField(field);
                        setSortDirection(direction);
                    }}
                />
                <FileList
                    files={filteredAndSortedFiles}
                    onNavigate={handleNavigate}
                    currentPath={currentPath}
                    deleteFile={deleteFile}
                    downloadFile={downloadFile}
                    setItemToRename={setItemToRename}
                    setIsRenameModalOpen={setIsRenameModalOpen}
                    moveItems={moveItems}
                    token={token}
                />
                <FileUploader folder={currentPath} multiple={true} onUploadComplete={handleFilesUploaded} />
                <NewFolderModal
                    isOpen={isModalOpen}
                    onClose={() => setModalOpen(false)}
                    onCreate={handleCreateFolder}
                />
                <RenameModal
                    isOpen={isRenameModalOpen}
                    onClose={() => setIsRenameModalOpen(false)}
                    onRename={handleRename}
                    initialName={itemToRename ? itemToRename.baseName : ""}
                />

            </div>
        </div>
    );
}

export default Drive;
