import React, { useContext } from 'react';
import UpdateProfile from '../../components/UpdateProfile';
import ChangePassword from '../../components/ChangePassword';
import { AuthContext } from '../../AuthContext';
import './Settings.css';

const Settings = () => {
    const { authenticatedUser } = useContext(AuthContext);
    const email = authenticatedUser?.email || '';

    return (
        <div className='SettingsContainer'>
            <h1 className='SettingsTitle'>Einstellungen</h1>
            <p>Deine Email: {email}</p>
            <UpdateProfile />
            <ChangePassword />
        </div>
    );
}

export default Settings;
