import React, { useState, useContext } from 'react';
import { AuthContext } from '../AuthContext';

const UpdateProfile = () => {
    const { authenticatedUser, setAuthenticatedUser } = useContext(AuthContext);
    const [firstName, setFirstName] = useState(authenticatedUser?.firstName || '');
    const [lastName, setLastName] = useState(authenticatedUser?.lastName || '');
    const [currentPassword, setCurrentPassword] = useState('');
    const [message, setMessage] = useState('');
    const [messageVisible, setMessageVisible] = useState(false);
    const [messageOpacity, setMessageOpacity] = useState(1);

    const handleProfileUpdate = (e) => {
        e.preventDefault();

        fetch(`http://localhost:4050/users/${authenticatedUser.id}/settings`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            },
            body: JSON.stringify({
                firstName,
                lastName,
                password: currentPassword
            })
        })
            .then(response => response.json())
            .then(data => {
                if (data.message) {
                    setMessage(data.message);
                    setMessageVisible(true);
                    // Start fade-out after a delay
                    setTimeout(() => {
                        setMessageOpacity(0);
                    }, 4000);

                    setTimeout(() => {
                        setMessageVisible(false);
                        setMessageOpacity(1); // Reset 
                    }, 5000);
                }
                if (data.user) {
                    setAuthenticatedUser(data.user);
                }
            })
            .catch(error => console.error('Error:', error));
    };


    return (
        <div>
            <form onSubmit={handleProfileUpdate}>
                <h2>Profil Aktualisieren</h2>
                <label>
                    Vorname:
                    <input type="text" value={firstName} onChange={e => setFirstName(e.target.value)} />
                </label>
                <label>
                    Nachname:
                    <input type="text" value={lastName} onChange={e => setLastName(e.target.value)} />
                </label>
                <label>
                    Aktuelles Passwort:
                    <input type="password" value={currentPassword} onChange={e => setCurrentPassword(e.target.value)} required />
                </label>
                <input type="submit" value="Änderungen speichern" />
            </form>
            {messageVisible && (
                <div className="message" style={{ opacity: messageOpacity }}>
                    {message}
                </div>
            )}
        </div>
    );
}

export default UpdateProfile;
