import React, { useState, useEffect, useContext } from 'react';
import './AdminDashboard.css';
import AdminSettings from '../pages/authenticated/AdminSettings';
import AdminUserEdit from '../pages/authenticated/AdminUserEdit';
import AdminUserRoleManagement from './AdminUserRoleManagement';
import Drive from '../pages/authenticated/Drive';
import { AuthContext } from '../AuthContext';
import { useNavigate } from 'react-router-dom';


const AdminDashboard = () => {
    const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);
    const [activeComponent, setActiveComponent] = useState("home");
    const { authenticatedUser } = useContext(AuthContext);
    const navigate = useNavigate();

    const toggleSidebar = () => {
        setIsSidebarCollapsed(!isSidebarCollapsed);
    }

    const renderComponent = () => {
        switch (activeComponent) {
            case "editUserFiles":
                return <Drive />;
            case "listEditUsers":
                return <AdminUserEdit />;
            case "systemSettings":
                return <AdminSettings />;
            case "manageUserRoles":
                return <AdminUserRoleManagement />;
            default:
                return <div className="content">
                    <h1 className='dashboardTitle'>Admin Dashboard</h1>
                    <h2>Hello {authenticatedUser.firstName} {authenticatedUser.lastName}</h2>

                    {/* Your Page Content Here */}
                </div>;
        }
    }

    return (
        <div className='adminDashboardContainer'>
            <div className={`sidebarAdmin ${isSidebarCollapsed ? 'collapsed' : ''}`}>
                <button onClick={toggleSidebar} className="toggleSidebar">{isSidebarCollapsed ? '>' : '<'}</button>
                {isSidebarCollapsed ? <></> : 
                <div className="menu">
                    <div className="menuItem" onClick={() => setActiveComponent("home")}>
                        <i className="icon-dashboard icon"></i> <span className='menuItemSpan'>Admin Dashboard</span>
                    </div>
                    <div className="menuItem" onClick={() => setActiveComponent("listEditUsers")}>
                        <i className="icon-users icon"></i> <span className='menuItemSpan'>List & Edit Users</span>
                    </div>
                    <div className="menuItem" onClick={() => setActiveComponent("manageUserRoles")}>
                        <i className="icon-roles icon"></i> <span className='menuItemSpan'>Edit User Roles</span>
                    </div>
                    <div className="menuItem" onClick={() => setActiveComponent("systemSettings")}>
                        <i className="icon-settings icon"></i> <span className='menuItemSpan'>System Settings</span>
                    </div>
                    <div className="seperatorAdminSideBar"></div>
                    <div className="menuItem" onClick={() => navigate('/drive/')}>
                        <i className="icon-edit-files icon"></i> <span className='menuItemSpan'>Edit My Files</span>
                    </div>
                </div>
                }
            </div>

            <div className="content">
                {renderComponent()}
            </div>
        </div>
    );
}

export default AdminDashboard;
